.main-layout-container{
    display: flex;
    flex-direction: row;

    .content-wrapper{
        width: 100%;
        padding: 1rem 1rem 1rem 0;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}